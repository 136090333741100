import "./ExploreContainer.css";

interface ContainerProps {
  name: string;
}

const ExploreContainer: React.FC<ContainerProps> = ({ name }) => {
  return (
    <div className="container">
      <strong>{name}</strong>
      <p>
        Explore{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://ionicframework.com/docs/components"
        >
          UI Components
        </a>
      </p>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
      <h1>ADDED SOME CHANGES</h1>
    </div>
  );
};

export default ExploreContainer;
